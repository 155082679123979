/*
*
* Base
*
* Template styles for base core items such as root, html and body.
*
*/

:root {
  scroll-behavior: initial;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;
}

body {
  width: 100%;
  margin: 0;
  font-family: var(--font);
  font-weight: 400;
  color: var(--body);
  background-color: var(--background);
  font-size: 87.5%;
}

#root {
  opacity: 1;
}

html:not([data-show='true']) body.spinner {
  #root {
    opacity: 0;
  }

  &:after {
    border-color: #7d7d7d;
    border-right-color: transparent;
  }
}

html[data-show='true'] {
  scroll-behavior: smooth;
  #root {
    opacity: 1;
  }
}
