.loading-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(163, 163, 163, 0.2);
    z-index: 1100 !important;
}
.loading-block-spinner-style {
    border-width: 0.15em;
    margin-right: 5px;
    position: absolute;
    top: 10rem;
    right: 0px;
    left: 45rem;
    border: 0.25em solid white;
    border-right-color: transparent;
    width: 3rem;
    height: 3rem;
    // color: white;
    // font-family: 'Montserrat';
}