@font-face {
  font-family: "Montserrat-Light";
  src: url("../../../fonts/Montserrat/static/Montserrat-Light.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../fonts/Montserrat/static/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../../fonts/Montserrat/static/Montserrat-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../../fonts/Montserrat/static/Montserrat-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../../fonts/Montserrat/static/Montserrat-Bold.ttf") format('truetype');
}
