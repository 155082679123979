/*
*
* Table
*
* Bootstrap table styles.
*
*/

.table {
  --bs-table-bg: rgba(0, 0, 0, 0);
  --bs-table-striped-color: var(--body);
  --bs-table-striped-bg: rgba(var(--body-rgb), 0.05);
  --bs-table-active-color: var(--body);
  --bs-table-active-bg: rgba(var(--body-rgb), 0.1);
  --bs-table-hover-color: var(--body);
  --bs-table-hover-bg: rgba(var(--body-rgb), 0.075);
  color: var(--body);
  border-color: var(--separator-light);
  margin-bottom: 0;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--separator);
}

.table > :not(caption) > * > * {
  --bs-table-bg: rgba(0, 0, 0, 0);
  color: var(--body);
}

.table-bordered > :not(caption) > * {
  border-color: var(--separator);
}

.table-active {
  background-color: #717778;
}
