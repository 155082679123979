/*
*
* Shared
*
* Rest of the theme variables.
*
*/

$font: 'Nunito Sans', sans-serif;
$font-heading: 'Montserrat', sans-serif;

:root {
  --transition-time: 400ms;
  --transition-time-long: 1000ms;
  --transition-time-short: 200ms;
  --nav-size-slim: 5rem;
  --nav-size: 18rem;
  --footer-size: 4.5rem;
  --input-height: 2.25rem;
  --small-title-height: 2rem;
  --font: 'Montserrat', sans-serif;
  --font-heading: 'Montserrat', sans-serif;
}

html[data-radius='rounded'] {
  --border-radius-lg: 16px;
  --border-radius-md: 10px;
  --border-radius-sm: 6px;
  --border-radius-xl: 50px;
}

html[data-radius='standard'] {
  --border-radius-lg: 6px;
  --border-radius-md: 4px;
  --border-radius-sm: 3px;
  --border-radius-xl: 4px;
}

html[data-radius='flat'] {
  --border-radius-lg: 0;
  --border-radius-sm: 0;
  --border-radius-md: 0;
  --border-radius-xl: 0;
}

:root {
  --card-spacing: 2rem;
  --card-spacing-sm: 1.25rem;
  --card-spacing-xs: 0.75rem;
  --card-spacing-sm-horizontal: 1.75rem 1.25rem;
  --card-spacing-sm-vertical: 1.25rem 1.75rem;

  --main-spacing-horizontal: 2.5rem;
  --main-spacing-vertical: 2rem;
  --title-spacing: 1.25rem;

  @include respond-below(lg) {
    --main-spacing-horizontal: 2rem;
  }

  @include respond-below(md) {
    --nav-size-slim: 4rem;
    --title-spacing: 1rem;
    --footer-size: 4rem;
  }

  @include respond-below(sm) {
    --card-spacing: 1.75rem;
    --footer-size: 3rem;
    --main-spacing-horizontal: 1.25rem;
    --main-spacing-vertical: 1.25rem;
  }
}
