@import "./_fonts.scss";
@import "./_color.scss";
@import 'react-loading-skeleton/dist/skeleton.css';

body {
  background-color: $body-bg !important;
  font-family: "Montserrat" !important;
  height: 100%;
}

textarea {
  resize: none;
}

.w-cursor {
  cursor: pointer !important;
}

.no-cursor {
  cursor: default !important;
}

.version-sidebar {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.nav-container {
  background-color: $primary !important;
  background-image: none !important;
}

.menu-items {
  display: flex;
  align-items: center;
}

.progress {
  height: 20px;
  border-radius: 6px;
  background-color: $dark !important;

  .progress-bar {
    background-color: $primary;
    border-radius: 6px !important;
  }
}

.b1 {
  font-family: "Montserrat-Medium";
  font-size: 18px;
}

.b2 {
  font-family: "Montserrat";
  font-size: 16px;
}

.s1 {
  font-family: "Montserrat-Medium";
  font-size: 32px;
}

.s2 {
  font-family: "Montserrat-Medium";
  font-size: 28px;
}

.s3 {
  font-family: "Montserrat-Medium";
  font-size: 20px;
}

.s4 {
  font-family: "Montserrat";
  font-size: 18px;
}

.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}

.btn {
  background-color: $primary !important;
  color: $body-bg !important;

  .outline-primary {
    // color: $primary !important;
    box-shadow: inset 0 0 0 1px $primary !important;
    color: $primary !important;
  }

  &:hover {
    background-color: $primary-darkened !important;
  }

  &:focus {
    background-color: $primary-darkened !important;
  }

  &:disabled {
    background-color: $primary-disabled !important;
    color: $disabled-text !important;
  }
}

.btn-secondary {
  background-color: $dark !important;
  color: $primary !important;
  border: 1px solid $primary !important;

  &:hover {
    background-color: $dark !important;
    color: $primary-darkened !important;
    border: 1px solid $primary-darkened !important;
  }

  &:focus {
    background-color: $dark !important;
    color: $primary-darkened !important;
    border: 1px solid $primary-darkened !important;
  }

  &:disabled {
    background-color: $dark !important;
    color: $primary-disabled !important;
    border: 1px solid $primary-disabled !important;
    color: $disabled-text !important;
  }
}

.btn-outline-primary {
  color: $primary !important;
}

.modal {
  height: auto;
  margin: auto;
}

.btn-multiple-state {
  .spinner {
    width: 36px;
    text-align: center;
  }

  .spinner > span {
    width: 20px;
    height: 20px;
    // background-color: $body-bg;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;

    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid $body-bg; /* Change the color as needed */
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 20%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: $body-bg;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: darken($dark, 10%);
  }
}

.table > tbody > tr:last-child > td {
  border-bottom: 0;
}

.table-divided {
  border: 0px !important;

  // temporarily hide the pagination
  .pagination-bottom {
    display: none;
  }

  .rt-thead.-header {
    box-shadow: none;
    background-color: transparent !important;
  }

  .rt-table {
    overflow: visible;
  }

  .rt-tr-group {
    border: none !important;
  }

  .rt-tr {
    border-radius: 0.75rem;
  }

  .rt-td {
    font-family: "Montserrat" !important;
  }

  .rt-th {
    font-family: "Montserrat" !important;
    // font-weight: 600 !important;
    color: $light !important;
    text-align: left !important;
  }

  .rt-td,
  .rt-th {
    font-size: 16px !important;
    align-self: center;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    &:first-child {
      padding-left: 40px;
    }

    // &:last-child {
    //   padding-right: 40px;
    // }
  }
  // added to show the button dropdown
  .td-action {
    overflow: unset;

    .btn-group {
      padding-right: 15px;
      .btn {
        padding-top: 6px;
        text-transform: capitalize;
      }
    }
  }

  .rt-tbody {
    .rt-tr {
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 20px;
      border-radius: 12px;
      background-color: $dark;
      color: $light;
    }
    // .rt-tr {
    //   border-radius: 0.75rem;
    //   background-color: $dark;
    // }
    // added this to avoid having two horizontal scrollbar for table
    overflow-x: visible;
    overflow-y: visible;
    // padding-bottom: 2rem !important;
  }
}